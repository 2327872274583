import React, { useState } from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import { flatDeep, containsObject } from "../../utils/functions";
import GalleryCard from "../../components/gallery-card/layout-01";
import { LightgalleryProvider } from "react-lightgallery";
//import "lightgallery.js/dist/css/lightgallery.css";
const GallerySection = ({ data }) => {
    const categoriesQuery = useStaticQuery(graphql`
        query CategorieQuery {
            allGallery {
                edges {
                    node {
                        category {
                            slug
                            title
                        }
                    }
                }
            }
        }
    `);
    const categories = categoriesQuery.allGallery.edges;
    const allCategories = flatDeep(
        categories.map((cate) => cate.node.category)
    );
    const cats = [];
    allCategories.forEach((cat) => {
        const obj = {
            title: cat.title,
            slug: cat.slug,
            count: 1,
        };
        const objIndex = containsObject(obj, cats);
        if (objIndex !== -1) {
            const prevCount = cats[objIndex].count;
            cats[objIndex] = {
                title: cat.title,
                slug: cat.slug,
                count: prevCount + 1,
            };
        } else {
            cats.push(obj);
        }
    });

    // Gallery content query
    const [selectedCategory, setSelectedCategory] = useState("all");

    const [galleryPost, setgalleryPost] = useState(data?.items || []);

    const categoryhandler = (value) => {
        const streaming = data?.items || [];
        if (value === "all") {
            setgalleryPost(streaming);
            setSelectedCategory(value);
            return;
        }
        const filteredItem = streaming.filter((item) =>
            item.category.some((cat) => cat.slug === value)
        );
        setgalleryPost(filteredItem);
        setSelectedCategory(value);
    };
    return (
        <section className="gallery-section section-space-pb border-b">
            <div className="container-full">
                <div className="filter-button-wrap mb-10 text-left space-x-4 mr-14">
                    <button
                        className={
                            selectedCategory === "all"
                                ? "filter-button active"
                                : "filter-button"
                        }
                        type="button"
                        onClick={() => categoryhandler("all")}
                    >
                        <span className="filter-text text-[18px]">All</span>
                    </button>
                    {cats.map((category) => (
                        <button
                            key={category.slug}
                            className={
                                selectedCategory === category.slug
                                    ? "filter-button active"
                                    : "filter-button"
                            }
                            type="button"
                            onClick={() => categoryhandler(category.slug)}
                        >
                            <span className="filter-text text-[18px]">
                                {category.title}
                            </span>
                        </button>
                    ))}
                </div>
                <LightgalleryProvider videojs="true">
                    <div className="grid grid-cols-1 xs:grid-cols-2 md:grid-cols-3 gap-4">
                        {galleryPost.map((item) => (
                            <GalleryCard
                                key={item.id}
                                group={`any`}
                                thumb={item?.thumb?.src}
                                image={item?.image?.src?.publicURL}
                                video={item?.video}
                                title={item?.title}
                            />
                        ))}
                    </div>
                </LightgalleryProvider>
            </div>
        </section>
    );
};

GallerySection.propTypes = {
    data: PropTypes.shape({
        items: PropTypes.array,
    }),
};

export default GallerySection;
