import React from "react";
import Layout from "@layout";
import SEO from "@components/seo";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { normalizedData } from "@utils/functions";
import PageBreadcrumb from "../components/pagebreadcrumb";
import Footer from "../layouts/footer/layout-01";
import Header from "../layouts/header/layout-dark";
import GallerySection from "../container/gallery-area";

const GalleryPage = ({ data, location, pageContext }) => {
    const globalContent = normalizedData(data?.allGeneral?.nodes || []);
    const content = normalizedData(data?.page?.content || []);
    return (
        <Layout>
            <SEO title="Services page" pathname="/" />
            <Header data={{ ...globalContent?.["menu"] }} />
            <PageBreadcrumb
                pageContext={pageContext}
                location={location}
                title="Photos & Videos"
            />
            <GallerySection
                data={{
                    ...content?.["gallery-section"],
                    items: data?.allGallery?.nodes,
                }}
            />
            <Footer data={{ ...globalContent?.["footer"] }} />
        </Layout>
    );
};

GalleryPage.propTypes = {
    location: PropTypes.object,
    pageContext: PropTypes.object,
    data: PropTypes.shape({
        allGeneral: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        page: PropTypes.shape({
            content: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allGallery: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};
export const query = graphql`
    query GalleryPageQuery {
        allGeneral {
            nodes {
                section
                id
                menu {
                    ...Menu
                }
                footer {
                    ...Footer
                }
            }
        }
        page(title: { eq: "gallaryPage" }, pageType: { eq: innerpage }) {
            content {
                ...PageContentAll
            }
        }
        allGallery {
            nodes {
                id
                title
                category {
                    slug
                    title
                }
                video
                thumb {
                    src {
                        publicURL
                        childImageSharp {
                            gatsbyImageData(
                                formats: WEBP
                                placeholder: DOMINANT_COLOR
                                quality: 100
                            )
                        }
                    }
                    alt
                }
                image {
                    src {
                        publicURL
                        childImageSharp {
                            gatsbyImageData(
                                formats: WEBP
                                placeholder: DOMINANT_COLOR
                                quality: 100
                            )
                        }
                    }
                    alt
                }
            }
        }
    }
`;
export default GalleryPage;
